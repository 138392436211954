// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-android-js": () => import("./../../../src/pages/android.js" /* webpackChunkName: "component---src-pages-android-js" */),
  "component---src-pages-beta-js": () => import("./../../../src/pages/beta.js" /* webpackChunkName: "component---src-pages-beta-js" */),
  "component---src-pages-i-os-js": () => import("./../../../src/pages/iOS.js" /* webpackChunkName: "component---src-pages-i-os-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-qr-js": () => import("./../../../src/pages/qr.js" /* webpackChunkName: "component---src-pages-qr-js" */)
}

